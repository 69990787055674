// @flow
import styled, { css } from 'styled-components'
import { Link } from '@common'

export const Node = {
  FolderIcon: styled.img.attrs({
    src: `${process.env.PUBLIC_URL || ''}/assets/folder.svg`
  })`
    height: 1em;
    margin-left: var(--gap-small);
    margin-right: var(--gap-small);
  `,
  Link: styled(Link)`
    bottom: 0;
    cursor: default;
    display: block;
    left: 0;
    outline: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;

    :focus {
      background-color: rgba(255, 255, 255, 0.05);
    }

    :hover {
      background-color: rgba(255, 255, 255, 0.03);
    }
  `,
  OnlyEfb: styled.span`
    background-color: var(--orange);
    border-radius: 50%;
    flex-shrink: 0;
    height: 1em;
    margin-left: auto;
    margin-right: var(--gap);
    width: 1em;
  `,
  SubWrapper: styled.div`
    height: ${({ subHeight }) => subHeight};
    overflow: hidden;
    transition: height 0.3s ease-out;
  `,
  Title: styled.div`
    color: var(--white);
    display: flex;
    padding-bottom: var(--gap-small);
    padding-top: var(--gap-small);
    text-decoration: none;

    ${({ active }) =>
      active &&
      css`
        background-color: rgba(255, 255, 255, 0.05);
        font-weight: bold;
      `};

    ${({ hasChild, treeLevel }) =>
      !hasChild &&
      css`
        padding-left: calc(var(--gap) * ${({ treeLevel }) => treeLevel} + 1em);
      `};
  `,
  TitleText: styled.span`
    display: block;
    margin-right: var(--gap);
    width: 100%;
  `,
  TitleWrapper: styled.div`
    display: block;
    position: relative;
  `,
  Wrapper: styled.div`
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
    user-select: none;
  `
}

export const RootNode = {
  Wrapper: styled.div`
    transition: opacity var(--duration-long), transform var(--duration-long);

    ${({ isHidden }) =>
      isHidden &&
      css`
        opacity: 0;
        pointer-events: none;
        transform: translateX(-100%);
      `};
  `
}

export const Navigation = {
  Wrapper: styled.div`
    --spinner-background: var(--sidebar);
    --spinner-opacity: 1;

    background-color: var(--sidebar);
    display: flex;
    flex-direction: column;
    grid-area: navigation;
    overflow-y: auto;
    padding-bottom: var(--gap-small);
    padding-top: var(--gap-small);
    position: relative;

    ${({ isHidden }) =>
      isHidden &&
      css`
        overflow-y: hidden;
      `};
  `
}
