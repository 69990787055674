// @flow
import React from 'react'
import { Query } from 'react-apollo'
import { Queries } from 'efa-tools/fileLibrary'
import Content from '../containers/Content'
import { Route } from '../styled'
import type { ShowConfirmModal } from '@config/types'

type Props = {
  fileDetail: string | null,
  fileId?: string
}

export default ({ fileDetail, fileId, status }: Props) => (
  <Route.Wrapper fileDetail={fileDetail ? true : false}>
    <Queries>
      {({ file }) => (
        <Query fetchPolicy="cache-only" query={file} variables={{ fileId }}>
          {({ data: { file } }: any) => <Content file={file} loading={!fileDetail} />}
        </Query>
      )}
    </Queries>
  </Route.Wrapper>
)
