// @flow
import React from 'react'
import Buttons from './Buttons'
import { File } from '../styled'
import type { File as FileType, ShowConfirmModal } from '@config/types'

type Props = {
  file: FileType,
  opened: boolean,
  showConfirmModal: ShowConfirmModal
}

export default ({ file, opened, showConfirmModal }: Props) => (
  <File.Wrapper opened={opened}>
    <File.Clickable title={file.description || undefined} to={`/library/${file.folderId}/file/${file.id}`}>
      <File.Inner>
        <File.Icon extension={file.fileExtension} />

        {file.favourite && <File.FavouriteIcon />}

        <File.Name>{file.name}</File.Name>

        {file.validFrom && <File.Validity>{new Date(file.validFrom).toDateString()}</File.Validity>}
      </File.Inner>
    </File.Clickable>

    <File.Buttons>
      <Buttons
        confirmed={file.confirmed}
        downloadUrl={file.downloadUrl}
        fileId={file.id}
        required={file.required}
        showConfirmModal={showConfirmModal}
      />
    </File.Buttons>
  </File.Wrapper>
)
