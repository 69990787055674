// @flow
import React from 'react'
import { Query } from 'react-apollo'
import { Queries } from 'efa-tools/fileLibrary'
import Items from './Items'
import { Breadcrumbs } from '../styled'
import type { Folder, Store } from '@config/types'

type Props = {
  folderId: string,
  getParentsFromChildId: (folderId: string, folders: Folder[]) => Folder[]
} & $PropertyType<Store, 'common'>

export default ({ category, folderId, getParentsFromChildId }: Props) => (
  <Breadcrumbs.Wrapper>
    <Queries>
      {({ folders }) => (
        <Query fetchPolicy={'cache-only'} query={folders}>
          {({ data: { folders } }: any) => {
            // if (!folders) return null

            if (folders) folders = getParentsFromChildId(folderId, folders)

            const hasItems = folders ? (folders.length ? true : false) : false

            return (
              <Breadcrumbs.Content visible={folders}>
                {hasItems ? (
                  <Breadcrumbs.Link to="/">Home</Breadcrumbs.Link>
                ) : (
                  <Breadcrumbs.Current>
                    {category === 'favourite'
                      ? 'Favourite'
                      : category === 'required'
                        ? 'Required'
                        : category === 'stream'
                          ? 'Stream'
                          : category === 'search'
                            ? 'Search'
                            : 'Home'}
                  </Breadcrumbs.Current>
                )}

                {hasItems && <Items folders={folders} />}
              </Breadcrumbs.Content>
            )
          }}
        </Query>
      )}
    </Queries>
  </Breadcrumbs.Wrapper>
)
