// @flow
import React from 'react'
import { Router } from '@reach/router'
import Route from '../containers/Route'

export default () => (
  <Router>
    <Route path="library/:folderId/file/:fileId" />
  </Router>
)
