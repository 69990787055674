// @flow
import React, { Component } from 'react'
import { Search } from '../styled'

type Props = {
  search: (value: string | null) => Promise<void>,
  value: string
}

export default class extends Component<Props> {
  input: ?HTMLInputElement

  componentDidMount = () => {
    const { search, value } = this.props

    const [category, urlValue] = document.location.pathname.slice(1).split('/')

    if (category === 'search' && !value) search(decodeURI(urlValue))
  }

  search = (value: string) => {
    this.props.search(value).then(() => {
      if (!this.input) return

      this.input.focus()
    })
  }

  render() {
    const { value } = this.props

    return (
      <Search.Input
        onChange={({ target: { value } }) => this.search(value)}
        placeholder="Search files..."
        value={value || ''}
        ref={input => (this.input = input)}
      />
    )
  }
}
