// @flow
import React, { Component } from 'react'
import { Button, Modal } from '@common'
import { Auth } from '../styled'
import type { Credentials } from '@config/types'

const container = document.createElement('div')
container.classList.add('auth')
window.document.body.appendChild(container)

type Props = {
  error: {
    status: string,
    statusText: string
  } | null,
  fetching: boolean,
  submit: (credentials: Credentials) => void,
  token: string | null
}

type State = Credentials

export default class extends Component<Props, State> {
  state = {
    company: process.env.REACT_APP_AUTH_COMPANY || '',
    password: process.env.REACT_APP_AUTH_PASSWORD || '',
    user: process.env.REACT_APP_AUTH_USERNAME || ''
  }

  onChange = ({ target: { id, value } }: SyntheticInputEvent<HTMLInputElement>) => this.setState({ [id]: value })

  onSubmit = (event: SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault()

    const { company, password, user } = this.state

    this.props.submit({ company, user, password })
  }

  render() {
    const { error, fetching, token } = this.props
    const { company, password, user } = this.state

    return (
      <Modal container={container} visible={!token}>
        <Auth.TitleWrapper>
          <Auth.Title>File Library</Auth.Title>
          <Auth.SubTitle>EFA</Auth.SubTitle>
        </Auth.TitleWrapper>

        <Auth.Form onSubmit={this.onSubmit}>
          <Auth.Label htmlFor="company">Company</Auth.Label>
          <Auth.Input id="company" onChange={this.onChange} placeholder="Company" value={company} />

          <Auth.Label htmlFor="user">User</Auth.Label>
          <Auth.Input id="user" onChange={this.onChange} placeholder="User" value={user} />

          <Auth.Label htmlFor="password">Password</Auth.Label>
          <Auth.Input id="password" onChange={this.onChange} placeholder="Password" type="password" value={password} />

          <Button loading={fetching} type="submit">
            Login
          </Button>
        </Auth.Form>

        {error && (
          <Auth.Error>
            <Auth.ErrorHeading>Error {error.status}!</Auth.ErrorHeading>
            {error.statusText}.
          </Auth.Error>
        )}
      </Modal>
    )
  }
}
