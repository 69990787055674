// @flow
import React, { Component } from 'react'
import { Buttons } from '../styled'
import type { ConfirmModal, ShowConfirmModal } from '@config/types'

type Props = {
  confirmed: boolean,
  downloadUrl: string,
  fileId: string,
  required: boolean,
  showConfirmModal: ShowConfirmModal
}

export default class extends Component<Props> {
  confirm = (actionType: $PropertyType<ConfirmModal, 'actionType'>) => {
    const { fileId, showConfirmModal } = this.props

    showConfirmModal({ actionType, ids: [+fileId] })
  }

  render() {
    const { confirmed, downloadUrl, required } = this.props

    return !confirmed && required ? (
      <>
        <Buttons.Confirm onClick={() => this.confirm('confirm')}>Confirm</Buttons.Confirm>

        <Buttons.Download confirm onClick={() => this.confirm('download')}>
          Download
        </Buttons.Download>
      </>
    ) : (
      <Buttons.Download href={downloadUrl}>Download</Buttons.Download>
    )
  }
}
