// @flow
import { connect } from 'react-redux'
import { getToken } from '@components/Auth/selectors'
import Navigation from '../components/Navigation'
import { getHidden } from '../selectors'

const mapStateToProps = state => ({
  isHidden: getHidden(state),
  token: getToken(state)
})

export default connect(mapStateToProps)(Navigation)
