// @flow
import React, { Fragment } from 'react'
import Folder from './Folder'
import type { Folder as FolderType } from '@config/types'

type Props = {
  folders: FolderType[] | null,
  hasEfbFile: (folderId: string) => boolean,
  onlyEfb: boolean,
  parentId: string | null,
  root: boolean
}

export default ({ folders, hasEfbFile, onlyEfb, parentId, root }: Props) => (
  <Fragment>
    {!root && <Folder parentId={parentId} />}

    {folders &&
      folders.map(folder => <Folder folder={folder} hasEfbFile={hasEfbFile(folder.id)} key={folder.id} onlyEfb={onlyEfb} />)}
  </Fragment>
)
