// @flow
import React, { Component } from 'react'
import { Mutation, Query } from 'react-apollo'
import { Queries } from 'efa-tools/fileLibrary'
import { Modal } from '@common'
import Buttons from '@components/Content/components/Buttons'
import { Confirm } from '../styled'
import type { ShowConfirmModal } from '@config/types'

const container = document.createElement('div')
container.classList.add('confirm')
window.document.body.appendChild(container)

const TRANSITION = 200

type Props = {
  confirmModal: ShowConfirmModal,
  hideConfirmModal: () => void
}

type State = {
  loading: boolean
}

export default class extends Component<Props, State> {
  state = {
    loading: false
  }

  confirm = (confirm: any, downloadUrl: string) => {
    const {
      confirmModal: { actionType },
      hideConfirmModal
    } = this.props

    this.setState({ loading: true })

    confirm().then(() => {
      hideConfirmModal()

      if (actionType === 'download' && downloadUrl) window.location.href = downloadUrl

      setTimeout(() => this.setState({ loading: false }), TRANSITION)
    })
  }

  render() {
    const {
      confirmModal: { actionType, ids, visible },
      hideConfirmModal
    } = this.props

    const { loading } = this.state

    return (
      <Modal container={container} duration={TRANSITION} visible={visible}>
        <Confirm.Wrapper>
          <Confirm.Title>
            {actionType === 'confirm' ? 'Confirm' : 'Download'} {ids.length === 1 ? 'Document' : 'Documents'}
          </Confirm.Title>

          <Confirm.Warning>Read each statement bellow carefully!</Confirm.Warning>

          <Confirm.Text>
            By tapping on the <strong>"CONFIRM"</strong> button below I hereby declare that I will read the downloaded
            documents and report back to Travel Service if I don't understand them.
          </Confirm.Text>

          <Confirm.Text>
            I hereby also declare that I will download documents that were not downloaded successfully and will read them and
            report back if I don't understand them.
          </Confirm.Text>

          <Queries>
            {({ confirmFiles, file }: any) => (
              <Query cachePolicy="cache-only" query={file} variables={{ fileId: ids[0] }}>
                {({ data }: any) => (
                  <Mutation mutation={confirmFiles} variables={{ ids }}>
                    {confirm => (
                      <Confirm.Buttons>
                        {data &&
                          data.file && (
                            <>
                              <Confirm.Confirm
                                loading={loading}
                                onClick={() => this.confirm(confirm, data.file.downloadUrl)}
                              >
                                {actionType === 'confirm' ? 'Confirm' : 'Confirm & Download'}
                              </Confirm.Confirm>

                              <Confirm.Cancel onClick={hideConfirmModal}>Cancel</Confirm.Cancel>
                            </>
                          )}
                      </Confirm.Buttons>
                    )}
                  </Mutation>
                )}
              </Query>
            )}
          </Queries>
        </Confirm.Wrapper>
      </Modal>
    )
  }
}
