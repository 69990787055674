// @flow
import { connect } from 'react-redux'
import { getFileDetail } from '@common/selectors'
import Route from '../components/Route'

const mapStateToProps = state => ({
  fileDetail: getFileDetail(state)
})

export default connect(mapStateToProps)(Route)
