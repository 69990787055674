// @flow
import styled from 'styled-components'

export const GlobalFilter = {
  Checkbox: styled.input.attrs({
    type: 'checkbox'
  })`
    height: 0;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    width: 0;
    z-index: -9999;

    :checked + label {
      font-weight: bold;

      ::before {
        background-color: var(--white);
      }

      :hover {
        ::before {
          background-color: rgba(255, 255, 255, 0.7);
        }
      }
    }
  `,
  Label: styled.label`
    display: flex;
    padding: var(--gap-small) var(--gap) var(--gap-small) 0.5em;

    ::before {
      border: 1px solid var(--white);
      border-radius: 50%;
      box-sizing: border-box;
      content: '';
      display: block;
      height: 1em;
      margin-right: var(--gap-small);
      transition: background-color var(--duration);
      width: 1em;
    }

    :hover {
      ::before {
        background-color: rgba(255, 255, 255, 0.3);
      }
    }
  `,
  Wrapper: styled.div`
    background-color: var(--orange);
    grid-area: global-filter;
    position: relative;
    user-select: none;
  `
}
