// @flow
import styled from 'styled-components'

export const Auth = {
  Error: styled.aside`
    background-color: var(--red);
    color: var(--white);
    margin-top: var(--gap);
    padding: var(--gap);
  `,
  ErrorHeading: styled.span`
    display: block;
    font-weight: bold;
    margin-bottom: var(--gap);
  `,
  Form: styled.form`
    display: flex;
    flex-direction: column;
  `,
  Input: styled.input`
    background-color: rgba(0, 0, 0, 0.05);
    border: 0;
    border-radius: 0;
    margin-bottom: var(--gap);
    padding: var(--gap);
    transition: background-color var(--duration);

    :hover {
      background-color: rgba(0, 0, 0, 0.08);
    }

    :focus {
      background-color: rgba(0, 0, 0, 0.1);
    }
  `,
  Label: styled.label`
    display: block;
    font-size: 0.8em;
    margin-bottom: calc(var(--gap) / 2);
  `,
  SubTitle: styled.span`
    font-size: 0.8rem;
    opacity: 0.6;
  `,
  Title: styled.span`
    font-size: 1.4rem;
    font-weight: bold;
  `,
  TitleWrapper: styled.div`
    align-items: center;
    background-color: var(--sidebar);
    display: flex;
    color: var(--white);
    flex-direction: column;
    margin: calc(var(--gap) * -1) calc(var(--gap) * -1) var(--gap);
    padding: var(--gap);
  `
}
