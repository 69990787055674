// @flow
import React from 'react'
import { Query } from 'react-apollo'
import { connect } from 'react-redux'
import { Queries } from 'efa-tools/fileLibrary'
import { showConfirmModal } from '@common/actions'
import { getCategory, getFileDetail, getOnlyEfb, getSearch } from '@common/selectors'
import { getToken } from '@components/Auth/selectors'
import { hasEfbFile } from '@components/Navigation/selectors'
import List from '../components/List'
import type { ConfirmModal } from '@config/types'

const mapStateToProps = state => ({
  category: getCategory(state),
  fileDetail: getFileDetail(state),
  hasEfbFile: (folderId: string) => hasEfbFile(state, folderId),
  onlyEfb: getOnlyEfb(state),
  searchValue: getSearch(state),
  token: getToken(state)
})

const mapDispatchToProps = dispatch => ({
  showConfirmModal: (props: ConfirmModal) => showConfirmModal(props)
})

const QueriesList = props => (
  <Queries>
    {({ folder, folders }) => (
      <Query fetchPolicy="cache-only" query={folders}>
        {({ data: { folders } }: any) => (
          <Query fetchPolicy="cache-only" query={folder} variables={{ folderId: props.folderId }}>
            {({ data: { folder } }: any) => <List {...props} folder={folder} folders={folders} />}
          </Query>
        )}
      </Query>
    )}
  </Queries>
)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QueriesList)
