// @flow
import { connect } from 'react-redux'
import { fetchToken } from '../actions'
import { getError, getFetching, getToken } from '../selectors'
import Auth from '../components/Auth'
import type { Credentials } from '@config/types'

const mapStateToProps = state => ({
  error: getError(state),
  fetching: getFetching(state),
  token: getToken(state)
})

const mapDispatchToProps = dispatch => ({
  submit: ({ company, password, user }: Credentials) => dispatch(fetchToken({ company, password, user }))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Auth)
