// @flow
import React from 'react'
import { Folder } from '../styled'
import type { Folder as FolderType } from '@config/types'

type Props = {
  folder?: FolderType,
  hasEfbFile?: boolean,
  onlyEfb?: boolean,
  parentId?: string | null
}

export default ({ folder, hasEfbFile, onlyEfb, parentId }: Props) => (
  <Folder.Wrapper to={`/library/${parentId || (folder && folder.id) || ''}`}>
    {hasEfbFile && onlyEfb && <Folder.Efb />}

    <Folder.Icon />

    <Folder.Name>{folder ? folder.title : '..'}</Folder.Name>
  </Folder.Wrapper>
)
