// @flow
import { connect } from 'react-redux'
import { getOnlyEfb } from '@common/selectors'
import { getActiveFolderId, hasEfbFile, isOpened } from '../selectors'
import RootNode from '../components/RootNode'

const mapStateToProps = state => ({
  activeFolderId: getActiveFolderId(state),
  hasEfbFile: (folderId: string) => hasEfbFile(state, folderId),
  isOpened: (folderId: string) => isOpened(state, folderId),
  onlyEfb: getOnlyEfb(state)
})

export default connect(mapStateToProps)(RootNode)
