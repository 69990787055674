// @flow
import { connect } from 'react-redux'
import { hideConfirmModal } from '@common/actions'
import { getConfirmModal } from '@common/selectors'
import Confirm from '../components/Confirm'

const mapStateToProps = state => ({
  confirmModal: getConfirmModal(state)
})

const mapDispatchToProps = dispatch => ({
  hideConfirmModal: () => dispatch(hideConfirmModal())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Confirm)
