// @flow
import React from 'react'
import { Logo } from '../styled'

export default () => (
  <Logo.Wrapper to="/">
    <Logo.Title>File Library</Logo.Title>

    <Logo.SubTitle>EFA</Logo.SubTitle>

    <Logo.Version>{process.env.REACT_APP_VERSION}</Logo.Version>
  </Logo.Wrapper>
)
