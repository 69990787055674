// @flow
import { combineReducers } from 'redux'
import { HIDE_CONFIRM_MODAL, HIDE_MODAL, SHOW_CONFIRM_MODAL, SHOW_MODAL, TOGGLE_ONLY_EFB } from './actions'
import { NAVIGATED } from '@components/Root/actions'
import { SEARCH } from '@components/Search/actions'

const category = (state = 'library', { type, url }) => {
  switch (type) {
    case NAVIGATED:
      const path = url.pathname.slice(1).split('/')

      const [category] = path

      if (category === 'favourite') return 'favourite'
      if (category === 'required') return 'required'
      if (category === 'search') return 'search'
      if (category === 'stream') return 'stream'

      return 'library'

    default:
      return state
  }
}

const confirmModalDefaultState = {
  actionType: null,
  ids: [],
  visible: false
}

const confirmModal = (state = confirmModalDefaultState, { actionType, ids, type }) => {
  switch (type) {
    case HIDE_CONFIRM_MODAL:
      return { ...state, visible: false }

    case SHOW_CONFIRM_MODAL:
      return { actionType, ids, visible: true }

    default:
      return state
  }
}

const fileDetail = (state = null, { type, url }) => {
  switch (type) {
    case NAVIGATED:
      const [category, second, isFile, fileId] = url.pathname.slice(1).split('/')

      if (category !== 'library' && category !== 'search' && second) return fileId
      if (category === 'library' && isFile && fileId) return fileId

      return null

    default:
      return state
  }
}

const modal = (state = true, { type }) => {
  switch (type) {
    case HIDE_MODAL:
      return false

    case SHOW_MODAL:
      return true

    default:
      return state
  }
}

const onlyEfb = (state = false, { type }) => {
  switch (type) {
    case TOGGLE_ONLY_EFB:
      return !state

    default:
      return state
  }
}

const search = (state = null, { type, url, value }) => {
  switch (type) {
    case NAVIGATED:
      const [category] = url.pathname.slice(1).split('/')

      if (category !== 'search') return null

      return state

    case SEARCH:
      return value

    default:
      return state
  }
}

export default combineReducers({
  category,
  confirmModal,
  fileDetail,
  modal,
  onlyEfb,
  search
})
