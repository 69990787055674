// @flow
import { combineReducers } from 'redux'
import { FETCH_TOKEN_ERROR, FETCH_TOKEN_INIT, FETCH_TOKEN_OK, REMOVE_TOKEN } from './actions'

const error = (state = null, { status, statusText, type }) => {
  switch (type) {
    case FETCH_TOKEN_ERROR:
      return { status, statusText }

    case FETCH_TOKEN_INIT:
      return null

    default:
      return state
  }
}

const fetching = (state = false, { type }) => {
  switch (type) {
    case FETCH_TOKEN_ERROR:
    case FETCH_TOKEN_OK:
      return false

    case FETCH_TOKEN_INIT:
      return true

    default:
      return state
  }
}

const token = (state = null, { token, type }) => {
  switch (type) {
    case FETCH_TOKEN_ERROR:
    case FETCH_TOKEN_INIT:
    case REMOVE_TOKEN:
      return null

    case FETCH_TOKEN_OK:
      return token

    default:
      return state
  }
}

export default combineReducers({
  error,
  fetching,
  token
})
