// @flow
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBook, faExclamationTriangle, faStar, faStream } from '@fortawesome/free-solid-svg-icons'
import { TopNavigation } from '../styled'
import type { Store } from '@config/types'

type Props = $PropertyType<Store, 'common'>

export default ({ category }: Props) => (
  <TopNavigation.Wrapper>
    <TopNavigation.Button active={category === 'library'} color="primary" to={'/'}>
      <FontAwesomeIcon icon={faBook} />

      <TopNavigation.Label>Library</TopNavigation.Label>
    </TopNavigation.Button>

    <TopNavigation.Button active={category === 'required'} color="orange" to={'/required'}>
      <FontAwesomeIcon icon={faExclamationTriangle} />

      <TopNavigation.Label>Required</TopNavigation.Label>
    </TopNavigation.Button>

    <TopNavigation.Button active={category === 'favourite'} color="yellow" to={'/favourite'}>
      <FontAwesomeIcon icon={faStar} />

      <TopNavigation.Label>Favourite</TopNavigation.Label>
    </TopNavigation.Button>

    <TopNavigation.Button active={category === 'stream'} color="green" to={'/stream'}>
      <FontAwesomeIcon icon={faStream} />

      <TopNavigation.Label>Stream</TopNavigation.Label>
    </TopNavigation.Button>
  </TopNavigation.Wrapper>
)
