// @flow
import { connect } from 'react-redux'
import { getSearch } from '@common/selectors'
import { search } from '../actions'
import Search from '../components/Search'

const mapStateToProps = state => ({
  value: getSearch(state)
})

const mapDispatchToProps = dispatch => ({
  search: (value: string) => dispatch(search(value))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Search)
