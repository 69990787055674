// @flow
import { navigate } from '@reach/router'
import type { Dispatch, File } from '@config/types'

export const SEARCH = 'SEARCH'

export const search = (value: string) => (dispatch: Dispatch) => {
  dispatch({ type: SEARCH, value: value || null })

  return navigate(value ? `/search/${value}` : '/', { replace: true })
}
