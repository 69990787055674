// @flow
import React, { Component, Fragment } from 'react'
import Fuse from 'fuse.js'
import flatten from 'lodash/flatten'
import { Spinner } from '@common'
import Files from './Files'
import Folders from './Folders'
import { List } from '../styled'
import type { Folder, Store } from '@config/types'

type Props = {
  folder: Folder,
  folderId: string,
  folders: Folder[],
  hasEfbFile: (folderId: string) => boolean,
  onlyEfb: boolean,
  searchValue: string | null,
  token: string | null
} & $PropertyType<Store, 'common'>

export default class extends Component<Props> {
  getChildFolders = () => {
    const { folderId, folders } = this.props

    if (!folders) return null

    if (!folderId) return folders.filter(({ parentMasterId }) => parentMasterId === 0)

    const currentFolder = folders.find(({ id }) => id === folderId)

    if (!currentFolder) return null

    const childFolders = folders.filter(({ parentMasterId }) => parentMasterId === currentFolder.masterId)

    return childFolders.length ? childFolders : null
  }

  getParentId = () => {
    const { folder, folders } = this.props

    if (!folder || !folders) return null

    const parent = folders.find(({ masterId }) => masterId === folder.parentMasterId)

    if (!parent) return null

    return parent.id
  }

  getFavouriteFiles = () => {
    const { folders } = this.props

    if (!folders) return []

    return flatten(folders.map(({ files }) => files.filter(({ favourite }) => favourite)))
  }

  getRequiredFiles = () => {
    const { folders } = this.props

    if (!folders) return []

    return flatten(folders.map(({ files }) => files.filter(({ required }) => required)))
  }

  getSearchFiles = () => {
    const { category, folders, searchValue } = this.props

    if (!folders) return []
    if (category === 'search' && !searchValue) return []

    const files = flatten(
      folders.map(({ files }) =>
        files.map(({ keywords, ...file }) => ({
          ...file,
          keywords: keywords ? keywords.map(({ name }) => name).join(', ') : null
        }))
      )
    )

    const fuse = new Fuse(files, {
      keys: ['description', 'fileName', 'name', 'keywords', 'labels'],
      shouldSort: true,
      threshold: 0.5
    })

    return fuse.search(searchValue)
  }

  render() {
    const { category, fileDetail, folder, folderId, hasEfbFile, onlyEfb, token } = this.props

    const folders = this.getChildFolders()

    return (
      <List.Wrapper>
        {token && (
          <Fragment>
            <Spinner visible={!folder && !folders} />

            <List.Alert efb={onlyEfb} />

            {category === 'library' && (
              <Folders
                folders={folders}
                hasEfbFile={hasEfbFile}
                onlyEfb={onlyEfb}
                parentId={this.getParentId()}
                root={!folderId}
              />
            )}

            {category === 'favourite' && <Files files={this.getFavouriteFiles()} onlyEfb={onlyEfb} />}

            {category === 'required' && <Files files={this.getRequiredFiles()} onlyEfb={onlyEfb} />}

            {category === 'search' && <Files files={this.getSearchFiles()} onlyEfb={onlyEfb} />}

            {folder && <Files fileDetail={fileDetail} files={folder.files} onlyEfb={onlyEfb} />}
          </Fragment>
        )}
      </List.Wrapper>
    )
  }
}
