// @flow
import styled from 'styled-components'
import { Button } from '@common'

export const Confirm = {
  Buttons: styled.div`
    display: flex;
    margin-top: var(--gap);
  `,
  Cancel: styled(Button)`
    --button-background: var(--red);
    --button-background-hover: var(--red);
    --spinner-background: var(--red);
  `,
  Confirm: styled(Button)`
    --button-background: var(--green);
    --button-background-hover: var(--green);
    --spinner-background: var(--green);
  `,
  Text: styled.p`
    margin-bottom: var(--gap-small);
    margin-top: 0;
  `,
  Title: styled.span`
    display: block;
    font-size: 1.6rem;
    font-weight: bold;
    text-align: center;
  `,
  Warning: styled.span`
    color: var(--orange);
    display: block;
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: var(--gap);
    text-align: center;
  `,
  Wrapper: styled.div`
    max-width: 480px;
  `
}
