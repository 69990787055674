// @flow
import { connect } from 'react-redux'
import { showConfirmModal } from '@common/actions'
import { getFileDetail } from '@common/selectors'
import { search } from '@components/Search/actions'
import Content from '../components/Content'
import type { ConfirmModal } from '@config/types'

const mapDispatchToProps = dispatch => ({
  search: (value: string) => dispatch(search(value)),
  showConfirmModal: (props: ConfirmModal) => dispatch(showConfirmModal(props))
})

export default connect(
  null,
  mapDispatchToProps
)(Content)
