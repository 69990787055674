// @flow
import { connect } from 'react-redux'
import { getCategory } from '@common/selectors'
import TopNavigation from '../components/TopNavigation'

const mapStateToProps = state => ({
  category: getCategory(state)
})

export default connect(mapStateToProps)(TopNavigation)
