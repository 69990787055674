// @flow
import { Router } from '@reach/router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import { Button as CommonButton, Link } from '@common'
import styled, { css } from 'styled-components'

const Button = styled(CommonButton)`
  --button-background: transparent;
  --button-background-hover: ${({ color }) => `var(--${color})`};
  --button-color: ${({ color }) => `var(--${color})`};
  --button-color-hover: var(--white);
  --button-gap: 0 1rem;
  --button-hover-opacity: 1;
  --duration: 0;
  --spinner-background: ${({ color }) => `var(--${color})`};
`

export const Buttons = {
  Confirm: styled(Button).attrs({ color: 'green' })``,
  Download: styled(Button).attrs({ color: 'primary', link: ({ confirm }) => !confirm })``
}

const Icon = styled.img`
  height: 1em;
  margin-right: var(--gap-small);
  position: relative;
`

const Row = styled.div`
  align-items: center;
  cursor: default;
  display: flex;
  height: 2.5rem;
  position: relative;
  user-select: none;

  :hover {
    background-color: var(--white);
  }
`

export const Folder = {
  Efb: styled.div`
    background-color: var(--orange);
    border-radius: 50%;
    height: 1em;
    margin-right: var(--gap-small);
    width: 1em;
  `,
  Name: styled.div`
    flex: 1;
  `,
  Icon: styled(Icon).attrs({
    src: `${process.env.PUBLIC_URL || ''}/assets/folder.svg`
  })``,
  Wrapper: styled(Row.withComponent(Link))`
    background-color: rgba(255, 255, 255, 0.7);
    color: var(--black);
    outline: 0;
    padding-left: var(--gap);
    padding-right: var(--gap);
    text-decoration: none;
  `
}

export const File = {
  Buttons: styled.div`
    --spinner-color: var(--white);
    --spinner-opacity: 0.8;

    display: flex;
    height: 100%;
    text-decoration: none;
  `,
  Clickable: styled(Link)`
    align-items: center;
    color: rgba(0, 0, 0, 0.8);
    cursor: default;
    display: flex;
    flex: 1;
    height: 100%;
    outline: 0;
    position: relative;
    text-decoration: none;
  `,
  FavouriteIcon: styled(FontAwesomeIcon).attrs({ icon: faStar })`
    color: var(--yellow);
    margin-right: var(--gap-small);
  `,
  Icon: styled(Icon).attrs({
    src: ({ extension }) =>
      `${process.env.PUBLIC_URL || ''}/assets/${(() => {
        switch (extension) {
          case 'csv':
            return 'csv'

          case 'doc':
          case 'docx':
            return 'doc'

          case 'json':
            return 'json'

          case 'pdf':
            return 'pdf'

          case 'ppt':
          case 'pptx':
            return 'ppt'

          case 'txt':
            return 'txt'

          case 'xls':
          case 'xlsx':
            return 'xls'

          case 'xml':
            return 'xml'

          case 'zip':
            return 'zip'

          default:
            return 'file'
        }
      })()}.svg`
  })``,
  Inner: styled.span`
    align-items: center;
    bottom: 0;
    display: flex;
    left: 0;
    padding-left: var(--gap);
    padding-right: var(--gap);
    position: absolute;
    right: 0;
    top: 0;
  `,
  Name: styled.span`
    display: block;
    margin-right: var(--gap);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `,
  Validity: styled.span`
    display: block;
    color: var(--gray-dark);
    flex-shrink: 0;
    font-size: 0.75rem;
    margin-left: auto;
  `,
  Wrapper: styled(Row)`
    background-color: rgba(255, 255, 255, 0.2);

    ${({ opened }) =>
      opened &&
      css`
        background-color: var(--gray);
        pointer-events: none;
      `};
  `
}

export const Files = {
  Missing: styled.div`
    align-items: center;
    color: var(--gray);
    display: flex;
    flex: 1;
    font-size: 3em;
    font-style: italic;
    justify-content: center;
    min-height: 100%;
    padding-bottom: var(--gap);
    padding-top: var(--gap);
    user-select: none;
  `
}

export const List = {
  Alert: styled.div`
    background-color: var(--orange);
    height: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: height var(--duration);
    z-index: 2;

    ${({ efb }) =>
      efb &&
      css`
        height: 0.5em;
      `};
  `,
  Wrapper: styled.div`
    --spinner-background: var(--content);
    --spinner-color: var(--primary);
    --spinner-opacity: 1;

    display: flex;
    flex-direction: column;
    min-height: 100%;
  `
}

export const Content = {
  Route: styled.div`
    box-sizing: border-box;
    min-height: 100%;
    padding: calc(var(--gap) * 2 + 0.2em) calc(var(--gap) * 2) calc(var(--gap) * 2);

    > * {
      height: 100%;
    }
  `,
  Router: styled(Router)`
    background-color: var(--content);
    grid-area: content;
    overflow: auto;
    position: relative;
  `
}
