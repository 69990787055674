// @flow
import { connect } from 'react-redux'
import { logout } from '@components/Auth/actions'
import { getToken } from '@components/Auth/selectors'
import Logout from '../components/Logout'

const mapStateToProps = state => ({
  token: getToken(state)
})

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logout())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Logout)
