// @flow
import React from 'react'
import { createPortal } from 'react-dom'
import styled, { css } from 'styled-components'
import type { Node } from 'react'

const Inner = styled.div`
  background-color: var(--white);
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.3);
  margin-bottom: auto;
  margin-top: auto;
  padding: var(--gap);
`

const Wrapper = styled.div`
  align-items: center;
  background-image: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8));
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  opacity: 0;
  overflow: auto;
  padding: var(--gap);
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity var(--duration-long), z-index var(--duration-long) linear var(--duration-long);
  z-index: -1;

  ${({ duration }) =>
    duration &&
    css`
      --duration-long: ${duration}ms;
    `};

  ${({ visible }) =>
    visible &&
    css`
      opacity: 1;
      transition: opacity var(--duration-long);
      z-index: 9999;
    `};
`

type Props = {
  children: Node,
  container: Element,
  duration?: number,
  visible: boolean
}

export default ({ children, container, duration, visible }: Props) =>
  createPortal(
    <Wrapper duration={duration} visible={visible}>
      <Inner>{children}</Inner>
    </Wrapper>,
    container
  )
