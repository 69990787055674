// @flow
import React, { Component } from 'react'
import { Node } from '../styled'
import type { Folder } from '@config/types'

type Props = {
  activeFolderId: string,
  getChildNode: (masterId: number) => Folder[],
  hasEfbFile: (folderId: string) => boolean,
  isOpened: (folderId: string) => void,
  node: Folder,
  onlyEfb: boolean
}

type State = {
  active: boolean,
  opened: boolean,
  subHeight: string,
  transitionDuration: number
}

class NodeComponent extends Component<Props, State> {
  SubWrapper: window.Node

  timeouts: TimeoutID[] = []

  state = {
    active: false,
    opened: false,
    subHeight: '0px',
    transitionDuration: 0
  }

  static getDerivedStateFromProps = ({ activeFolderId, isOpened, node: { id } }: Props) => ({
    active: activeFolderId === id,
    opened: isOpened(id)
  })

  componentDidMount = () =>
    this.setState({ transitionDuration: parseFloat(getComputedStyle(this.SubWrapper).transitionDuration) * 1000 })

  componentDidUpdate = (prevProps: Props, { opened }: State, subHeight: string) => {
    if (!subHeight) return

    this.setSubWrapperHeight(opened)
  }

  getSnapshotBeforeUpdate = (prevProps: Props, prevState: State) => {
    const { opened } = this.state

    if (prevState.opened === opened) return null

    const subHeight = this.getSubWrapperHeight()

    this.setState({ subHeight })

    return subHeight
  }

  getSubWrapperHeight = () => {
    const clone = this.SubWrapper.cloneNode(true)

    this.SubWrapper.parentNode.appendChild(clone)

    clone.style.height = 'auto'

    const subHeight = `${clone.scrollHeight}px`

    this.SubWrapper.parentNode.removeChild(clone)

    return subHeight
  }

  setSubWrapperHeight = (prevOpened: boolean) => {
    const { opened, transitionDuration } = this.state

    this.timeouts.forEach(timeout => clearTimeout(timeout))

    if (!prevOpened && opened)
      this.timeouts[0] = setTimeout(
        () =>
          this.setState(
            { subHeight: this.getSubWrapperHeight() },
            () => (this.timeouts[1] = setTimeout(() => this.setState({ subHeight: 'auto' }), transitionDuration))
          ),
        transitionDuration
      )

    if (prevOpened && !opened) this.timeouts[2] = setTimeout(() => this.setState({ subHeight: '0px' }))
  }

  render() {
    const { activeFolderId, getChildNode, hasEfbFile, isOpened, node, onlyEfb } = this.props
    const { active, opened, subHeight } = this.state

    const childNode = getChildNode(node.masterId)
    const hasChild = childNode.length ? true : false

    return (
      <Node.Wrapper>
        <Node.TitleWrapper>
          <Node.Link to={`/library/${node.id}`} />

          <Node.Title active={active} hasChild={hasChild} treeLevel={node.treeLevel}>
            <Node.FolderIcon />

            <Node.TitleText>{node.title}</Node.TitleText>

            {onlyEfb && hasEfbFile(node.id) && <Node.OnlyEfb />}
          </Node.Title>
        </Node.TitleWrapper>

        <Node.SubWrapper opened={opened} ref={element => (this.SubWrapper = element)} subHeight={subHeight}>
          {childNode.map(child => (
            <NodeComponent
              activeFolderId={activeFolderId}
              getChildNode={getChildNode}
              hasEfbFile={hasEfbFile}
              isOpened={isOpened}
              key={child.id}
              node={child}
              onlyEfb={onlyEfb}
            />
          ))}
        </Node.SubWrapper>
      </Node.Wrapper>
    )
  }
}

export default NodeComponent
