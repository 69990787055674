// @flow
import React, { Fragment } from 'react'
import { Query } from 'react-apollo'
import { Queries } from 'efa-tools/fileLibrary'
import { Spinner } from '@common'
import RootNode from '../containers/RootNode'
import { Navigation } from '../styled'

type Props = {
  isHidden: boolean,
  token: string
}

export default ({ isHidden, token }: Props) => (
  <Navigation.Wrapper isHidden={isHidden}>
    {token && (
      <Queries>
        {({ folders }) => (
          <Query fetchPolicy={'cache-only'} query={folders}>
            {({ data: { folders } }: any) => (
              <Fragment>
                <Spinner visible={!folders && !isHidden} />

                {folders && <RootNode folders={folders} isHidden={isHidden} />}
              </Fragment>
            )}
          </Query>
        )}
      </Queries>
    )}
  </Navigation.Wrapper>
)
