// @flow
import { navigate } from '@reach/router'
import { hideModal, showModal } from '@common/actions'
import apollo from '@config/apollo'
import { persistor } from '@config/redux'
import type { Credentials, Dispatch, GetState } from '@config/types'

export const FETCH_TOKEN_ERROR = 'FETCH_TOKEN_ERROR'
export const FETCH_TOKEN_INIT = 'FETCH_TOKEN_INIT'
export const FETCH_TOKEN_OK = 'FETCH_TOKEN_OK'
export const REMOVE_TOKEN = 'REMOVE_TOKEN'

export const fetchToken = ({ company: auth_company, password: auth_password, user: auth_username }: Credentials) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  dispatch({ type: FETCH_TOKEN_INIT })

  const body = JSON.stringify({ auth_company, auth_password, auth_username })

  const response = await fetch(`${process.env.REACT_APP_FILE_LIBRARY_URL || ''}/auth/authenticate`, {
    body,
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    method: 'POST'
  })

  if (response.status !== 200)
    return dispatch({ status: response.status, statusText: response.statusText, type: FETCH_TOKEN_ERROR })

  return await response.json().then(({ data: { user: { data: { auth_token: token } } } }) => {
    dispatch(hideModal())
    dispatch({ token, type: FETCH_TOKEN_OK })

    apollo.writeData({ data: { modal: false } })
  })
}

export const logout = () => (dispatch: Dispatch) => {
  dispatch({ type: 'LOGGING_OUT' })

  const promises = [apollo.clearStore(), persistor.purge()]

  Promise.all(promises).then(() => {
    dispatch({ type: 'RESET' })

    navigate('/')

    setTimeout(() => window.location.reload(), 500)
  })
}

export const removeToken = () => (dispatch: Dispatch) => {
  dispatch(showModal())
  dispatch({ type: REMOVE_TOKEN })
}
