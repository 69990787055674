// @flow
import React from 'react'
import { connect } from 'react-redux'
import { showConfirmModal } from '@common/actions'
import File from '../components/File'
import type { ConfirmModal } from '@config/types'

const mapDispatchToProps = dispatch => ({
  showConfirmModal: (props: ConfirmModal) => dispatch(showConfirmModal(props))
})

export default connect(
  null,
  mapDispatchToProps
)(File)
