// @flow
import React, { Component } from 'react'
import Node from './Node'
import { RootNode } from '../styled'
import type { Folder } from '@config/types'

type Props = {
  activeFolderId: string,
  folders: Folder[],
  hasEfbFile: (folderId: string) => boolean,
  isHidden: boolean,
  isOpened: (folderId: string) => void,
  onlyEfb: boolean,
  toggleNode: (folderId: string, opened: boolean) => void
}

type State = {
  rootFolders: Folder[]
}

export default class extends Component<Props, State> {
  state = {
    rootFolders: []
  }

  static getDerivedStateFromProps = ({ folders }: Props) => ({
    rootFolders: folders.filter(({ parentMasterId }) => parentMasterId === 0)
  })

  getChildNode = (masterId: number) => this.props.folders.filter(({ parentMasterId }) => parentMasterId === masterId)

  render() {
    const { activeFolderId, isHidden, isOpened, hasEfbFile, onlyEfb, toggleNode } = this.props
    const { rootFolders } = this.state

    return (
      <RootNode.Wrapper isHidden={isHidden}>
        {rootFolders.map(folder => (
          <Node
            activeFolderId={activeFolderId}
            getChildNode={this.getChildNode}
            hasEfbFile={hasEfbFile}
            isOpened={isOpened}
            key={folder.id}
            node={folder}
            onlyEfb={onlyEfb}
            toggleNode={toggleNode}
          />
        ))}
      </RootNode.Wrapper>
    )
  }
}
