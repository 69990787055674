// @flow
import styled, { css } from 'styled-components'
import { Link as DefaultLink } from '@common'

const Breadcrumb = styled.span`
  align-items: center;
  cursor: default;
  display: flex;
  padding: var(--gap);
`

export const Breadcrumbs = {
  Content: styled.div`
    display: flex;
    opacity: 0;
    transition: opacity var(--duration-long);

    ${({ visible }) =>
      visible &&
      css`
        opacity: 1;
      `};
  `,
  Current: styled(Breadcrumb)`
    font-weight: bold;
  `,
  Link: styled(Breadcrumb.withComponent(DefaultLink))`
    --color: rgba(0, 0, 0, 0.5);

    color: var(--color);
    margin-right: var(--gap);
    outline: 0;
    position: relative;
    text-decoration: none;
    transition: color var(--duration);

    :hover {
      color: var(--black);
    }

    ::after {
      color: var(--color);
      content: '>';
      display: flex;
      justify-content: center;
      pointer-events: none;
      position: absolute;
      right: calc(var(--gap) * -1);
      width: var(--gap);
    }
  `,
  Wrapper: styled.aside`
    background-color: var(--breadcrumbs);
    display: flex;
    grid-area: breadcrumbs;
    user-select: none;
  `
}
