// @flow
import styled, { css } from 'styled-components'
import { Button as CommonButton, Link } from '@common'

export const TopNavigation = {
  Button: styled(Link)`
    align-items: center;
    background-color: var(--gray-dark);
    /* background-color: var(--primary); */
    color: var(--white);
    /* color: ${({ color }) => (color ? `var(--${color})` : 'var(--white)')}; */
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    text-decoration: none;

    :hover span {
      height: 0.6rem;
      margin-top: 0.4rem;
    }

    ${({ active }) =>
      active &&
      css`
        background-color: ${({ color }) => `var(--${color})`};

        span {
          height: 0.6rem;
          margin-top: 0.2rem;
        }
      `};
  `,
  Label: styled.span`
    font-size: 0.6rem;
    height: 0;
    margin-top: 0;
    overflow: hidden;
    text-transform: uppercase;
    transition: height var(--duration), margin-top var(--duration);
  `,
  Wrapper: styled.div`
    display: flex;
    grid-area: top-navigation;
    user-select: none;
  `
}
