// @flow
import merge from 'lodash/merge'
import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloLink } from 'apollo-link'
import { onError } from 'apollo-link-error'
import { HttpLink } from 'apollo-link-http'
import apolloLogger from 'apollo-link-logger'
import { withClientState } from 'apollo-link-state'
import { state as authState } from 'efa-tools/auth'
import { state as commonState } from 'efa-tools/common'
import { state as fileLibraryState } from 'efa-tools/fileLibrary'
import store from '@config/redux'
import { removeToken } from '@components/Auth/actions'
import { getToken } from '@components/Auth/selectors'
// import { ApolloConfig } from 'efa-tools'

// const { cacheRedirects } = new ApolloConfig('Auth', 'FileLibrary')
// console.log({ cacheRedirects })

const cache = new InMemoryCache({
  cacheRedirects: {
    Query: {
      file: (_, { fileId }, { getCacheKey }) => getCacheKey({ __typename: 'File', id: fileId }),
      folder: (_, { folderId }, { getCacheKey }) => getCacheKey({ __typename: 'Folder', id: folderId })
    }
  },
  dataIdFromObject: ({ __typename, id }) => {
    switch (__typename) {
      case 'User':
        return null

      default:
        return id
    }
  }
})

const stateLink = withClientState({
  cache,
  ...merge(authState, commonState, fileLibraryState)
})

const authFetch = async (uri, options) => {
  const token = getToken(store.getState())

  options.headers.Authorization = `token ${token || ''}`

  const response = await fetch(uri, options)

  return response
}

const AuthLink = new HttpLink({
  fetch: authFetch,
  uri: `${process.env.REACT_APP_GRAPHQL_URL || ''}/graphql`
})

// const ErrorLink = new onError(({ graphQLErrors }) => {
//   const hasAuthError = graphQLErrors.find(({ message }) => message.includes('401')) ? true : false

//   if (hasAuthError) store.dispatch(removeToken())
// })

const link = ApolloLink.from([/*apolloLogger, ErrorLink,*/ stateLink, AuthLink])

export default new ApolloClient({ cache, link })
