// @flow
import type { Folder, Store } from '@config/types'

export const getActiveFolderId = ({ navigation: { activeFolderId } }: Store) => activeFolderId

export const getFoldersWithEfbFiles = ({ navigation: { foldersWithEfbFiles } }: Store) => foldersWithEfbFiles

export const getHidden = ({ navigation: { hidden } }: Store) => hidden

export const getOpenedFoldersIds = ({ navigation: { openedFoldersIds } }: Store) => openedFoldersIds

export const getParentsFromChildId = (folderId: string, folders: Folder[]) => {
  const getParents = (parentMasterId: number, parents: Folder[]) => {
    const parent = folders.find(({ masterId }) => masterId === parentMasterId)

    if (parent) return getParents(parent.parentMasterId, [...parents, parent])

    return parents
  }

  let parents: any = []

  if (!folders) return []

  const folder = folders.find(({ id }) => id === folderId)

  if (folder) parents = getParents(folder.parentMasterId, [folder])

  return parents.reverse()
}

export const hasEfbFile = (state: Store, folderId: string) =>
  getFoldersWithEfbFiles(state).find(id => id === folderId) ? true : false

export const isOpened = (state: Store, folderId: string) =>
  getOpenedFoldersIds(state).find(id => id === folderId) ? true : false
