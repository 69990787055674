// @flow
import { createContext } from 'react';
import gql from 'graphql-tag';
import * as state from './state';
import { confirmFiles, toggleFavouriteFile } from './mutations';
import { file, folder, folders } from './queries';
export var queries = function queries() {
  return {
    confirmFiles: gql(confirmFiles),
    file: gql(file),
    folder: gql(folder),
    folders: gql(folders),
    toggleFavouriteFile: gql(toggleFavouriteFile)
  };
};

var _createContext = createContext(queries()),
    Queries = _createContext.Consumer;

export { Queries };
export { state };