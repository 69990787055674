// @flow
import { applyMiddleware, combineReducers, createStore } from 'redux'
import thunk from 'redux-thunk'
import { persistCombineReducers, persistReducer, persistStore } from 'redux-persist'
import { createWhitelistFilter } from 'redux-persist-transform-filter'
import reduxReset from 'redux-reset'
import storage from 'localforage'
import { composeWithDevTools } from 'redux-devtools-extension'
import common from '@common/reducers'
import auth from '@components/Auth/reducers'
import navigation from '@components/Navigation/reducers'

const persistConfig = {
  key: 'root',
  storage,
  transforms: [createWhitelistFilter('auth', ['token']), createWhitelistFilter('common', ['modal'])],
  whitelist: ['auth', 'common']
}

const persistedReducer: any = persistCombineReducers(persistConfig, {
  auth,
  common,
  navigation
})

const composeEnhancers = composeWithDevTools({ latency: 0 })

const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(thunk), reduxReset()))

export const persistor = persistStore(store)

export default store
