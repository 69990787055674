// @flow
import { queries } from './';
export var defaults = {
  token: null
};
export var resolvers = {
  Mutation: {
    setToken: function setToken(_, __, _ref) {
      var cache = _ref.cache;
      var token = new Date().toDateString();
      var data = {
        token: token
      };
      cache.writeData({
        data: data
      });
      return token;
    }
  }
};
var typeDefs = "\n  type Mutation {\n    setToken: String\n  }\n\n  type Query {\n    token: String\n  }\n";