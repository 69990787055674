// @flow
import React from 'react'
import { navigate } from '@reach/router'
import { Mutation } from 'react-apollo'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar as faStarRegular } from '@fortawesome/free-regular-svg-icons'
import { faStar, faTimes } from '@fortawesome/free-solid-svg-icons'
import { Queries } from 'efa-tools/fileLibrary'
import { Spinner } from '@common'
import Buttons from '@components/Content/components/Buttons'
import { Content } from '../styled'
import type { File, ShowConfirmModal } from '@config/types'

type Props = {
  file: File,
  loading: boolean,
  search: (value: string) => void,
  showConfirmModal: ShowConfirmModal
}

export default ({ file, loading, search, showConfirmModal }: Props) => (
  <Content.Wrapper>
    <Spinner visible={loading} />

    {file && (
      <>
        <Content.Buttons>
          <Queries>
            {({ file: query, toggleFavouriteFile }) => (
              <Mutation mutation={toggleFavouriteFile}>
                {(toggle: any) => (
                  <Content.Favourite
                    onClick={() =>
                      toggle({
                        optimisticResponse: {
                          __typename: 'Mutation',
                          toggleFavouriteFile: {
                            __typename: 'File',
                            favourite: !file.favourite,
                            id: file.id
                          }
                        },
                        update: (cache, { data: { toggleFavouriteFile } }) => {
                          const { file: oldFile } = cache.readQuery({ query, variables: { fileId: file.id } })

                          const data = {
                            file: { ...oldFile, ...toggleFavouriteFile }
                          }

                          cache.writeQuery({ data, query })
                        },
                        variables: { id: file.id }
                      })
                    }
                  >
                    {file.favourite ? <FontAwesomeIcon icon={faStar} /> : <FontAwesomeIcon icon={faStarRegular} />}
                  </Content.Favourite>
                )}
              </Mutation>
            )}
          </Queries>

          <Buttons
            confirmed={file.confirmed}
            downloadUrl={file.downloadUrl}
            fileId={file.id}
            required={file.required}
            showConfirmModal={showConfirmModal}
          />

          <Content.Close onClick={() => navigate('..')}>
            <FontAwesomeIcon icon={faTimes} />
          </Content.Close>
        </Content.Buttons>

        <Content.Inner>
          <Content.Row>
            <Content.Label>Document name</Content.Label>

            <Content.Value>{file.name || 'N/A'}</Content.Value>
          </Content.Row>

          {file.fileName && (
            <Content.Row>
              <Content.Label>File name</Content.Label>

              <Content.Value>
                <Content.FileName href={file.downloadUrl}>
                  {file.fileName}.{file.fileExtension}
                </Content.FileName>
              </Content.Value>
            </Content.Row>
          )}

          {file.contact && (
            <Content.Row>
              <Content.Label>Owner {'&'} Contact</Content.Label>

              <Content.Value>{file.contact}</Content.Value>
            </Content.Row>
          )}

          {file.createdAt && (
            <Content.Row>
              <Content.Label>Created</Content.Label>

              <Content.Value>{new Date(file.createdAt).toDateString()}</Content.Value>
            </Content.Row>
          )}

          {file.docType && (
            <Content.Row>
              <Content.Label>Document type</Content.Label>

              {file.docType}

              {file.confirmed && <Content.Confirmed>confirmed</Content.Confirmed>}
            </Content.Row>
          )}

          {(file.validFrom || file.validTo) && (
            <Content.Row>
              <Content.Label>Validity</Content.Label>

              <Content.Value>
                {file.validFrom && (
                  <>
                    <Content.Light>from</Content.Light> {`${new Date(file.validFrom).toDateString()} `}
                  </>
                )}

                {file.validTo && (
                  <>
                    <Content.Light>to</Content.Light> {new Date(file.validTo).toDateString()}
                  </>
                )}
              </Content.Value>
            </Content.Row>
          )}

          {file.description && (
            <Content.Row>
              <Content.Label>Document description</Content.Label>

              <Content.Value>{file.description}</Content.Value>
            </Content.Row>
          )}

          {file.labels && (
            <Content.Row>
              <Content.Label>Labels</Content.Label>

              <Content.Value>{file.labels}</Content.Value>
            </Content.Row>
          )}

          {file.keywords && (
            <Content.Row>
              <Content.Label>Keywords</Content.Label>

              <Content.Keywords>
                {file.keywords.map(({ name }) => (
                  <Content.Keyword key={name} onClick={() => search(name)}>
                    {name}
                  </Content.Keyword>
                ))}
              </Content.Keywords>
            </Content.Row>
          )}

          {file.changes && (
            <Content.Row>
              <Content.Label>Changes to the previous version</Content.Label>

              <Content.Value>{file.changes}</Content.Value>
            </Content.Row>
          )}
        </Content.Inner>
      </>
    )}
  </Content.Wrapper>
)
