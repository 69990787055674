// @flow
import styled from 'styled-components'

export const Search = {
  Input: styled.input`
    background-color: var(--search);
    border: 0;
    display: flex;
    font-size: 1.2rem;
    grid-area: search;
    padding: var(--gap);

    ::placeholder {
      font-style: italic;
      opacity: 0.4;
    }
  `
}
