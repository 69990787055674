// @flow
import { createContext } from 'react';
import gql from 'graphql-tag';
import * as state from './state';
import { hideModal, showModal } from './mutations';
import { modal } from './queries';
export var queries = function queries() {
  return {
    hideModal: gql(hideModal),
    modal: gql(modal),
    showModal: gql(showModal)
  };
};

var _createContext = createContext(queries()),
    Queries = _createContext.Consumer;

export { Queries };
export { state };