// @flow
import React from 'react'
import styled, { css, keyframes } from 'styled-components'

const SpinnerAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
`

const Spinner = styled.span`
  --border: ${({ size }) => size / 5}em solid var(--spinner-color);
  --border-background: ${({ size }) => size / 5}em solid transparent;

  animation: ${SpinnerAnimation} 0.8s infinite linear;
  border-bottom: var(--border-background);
  border-left: var(--border);
  border-right: var(--border-background);
  border-top: var(--border-background);
  transform: translateZ(0);

  &,
  ::after {
    border-radius: 50%;
    height: ${({ size }) => size}em;
    width: ${({ size }) => size}em;
  }
`

const Background = styled.span`
  backface-visibility: hidden;
  background-color: var(--spinner-background);
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
`

const Wrapper = styled.span`
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity var(--spinner-duration);
  z-index: 2;

  ${({ visible }) =>
    visible &&
    css`
      opacity: var(--spinner-opacity);
    `};
`

type Props = {
  size?: number,
  visible: boolean
}

export default ({ size = 1.5, visible }: Props) => (
  <Wrapper visible={visible}>
    <Spinner size={size} />

    <Background />
  </Wrapper>
)
