// @flow
import React from 'react'
import { Link } from '@reach/router'
import type { Node } from 'react'

type Props = {
  children?: Node,
  to: string
}

const setCurrentLinkAsActive = ({ isCurrent, isPartiallyCurrent }) => ({
  active: isCurrent || isPartiallyCurrent ? 'true' : null
})

export default ({ children, ...props }: Props) => (
  <Link {...props} getProps={setCurrentLinkAsActive}>
    {children}
  </Link>
)
