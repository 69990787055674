// @flow
import type { Store } from '@config/types'

export const getCategory = ({ common: { category } }: Store) => category

export const getConfirmModal = ({ common: { confirmModal } }: Store) => confirmModal

export const getFileDetail = ({ common: { fileDetail } }: Store) => fileDetail

export const getModal = ({ common: { modal } }: Store) => modal

export const getOnlyEfb = ({ common: { onlyEfb } }: Store) => onlyEfb

export const getSearch = ({ common: { search } }: Store) => search
