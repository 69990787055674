// @flow
import React from 'react'
import { Query } from 'react-apollo'
import { connect } from 'react-redux'
import { Queries as CommonQueries } from 'efa-tools/common'
import { Queries as FileLibraryQueries } from 'efa-tools/fileLibrary'
import { getFileDetail, getModal } from '@common/selectors'
import { getToken } from '@components/Auth/selectors'
import { navigated, setFoldersWithEfbFiles } from '../actions'
import Root from '../components/Root'
import type { Folder } from '@config/types'

const mapStateToProps = state => ({
  fileDetail: getFileDetail(state),
  token: getToken(state)
})

const mapDispatchToProps = dispatch => ({
  navigated: (href: string, folders: Folder[]) => dispatch(navigated(href, folders)),
  setFoldersWithEfbFiles: (folders: Folder[]) => dispatch(setFoldersWithEfbFiles(folders))
})

const QueriesRoot = props => (
  <CommonQueries>
    {({ modal }) => (
      <Query query={modal}>
        {({ data: { modal } }: any) => (
          <FileLibraryQueries>
            {({ folders }) => (
              <Query skip={!props.token} query={folders}>
                {({ data }) => <Root {...props} data={data} modal={modal} />}
              </Query>
            )}
          </FileLibraryQueries>
        )}
      </Query>
    )}
  </CommonQueries>
)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QueriesRoot)
