// @flow
import React, { Fragment } from 'react'
import { Breadcrumbs } from '../styled'
import type { Folder } from '@config/types'

type Props = {
  folders: Folder[]
}

export default ({ folders }: Props) => {
  const count = folders.length - 1

  return (
    <Fragment>
      {folders.map(
        ({ id, title }, i) =>
          i !== count ? (
            <Breadcrumbs.Link key={id} to={`/library/${id}`}>
              {title}
            </Breadcrumbs.Link>
          ) : (
            <Breadcrumbs.Current key={id}>{title}</Breadcrumbs.Current>
          )
      )}
    </Fragment>
  )
}
