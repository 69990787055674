// @flow
import styled from 'styled-components'
import { Link } from '@common'

export const Logo = {
  SubTitle: styled.span`
    font-size: 0.8rem;
    opacity: 0.6;
  `,

  Title: styled.span`
    font-size: 1.4rem;
    font-weight: bold;
  `,

  Version: styled.span`
    color: var(--white);
    font-size: 0.6em;
    position: absolute;
    right: calc(var(--gap) / 2);
    top: calc(var(--gap) / 2);
  `,

  Wrapper: styled(Link)`
    background-color: var(--sidebar);
    color: var(--white);
    display: flex;
    flex-direction: column;
    grid-area: logo;
    justify-content: center;
    padding: var(--gap);
    position: relative;
    text-decoration: none;
    transition: color var(--duration);
    user-select: none;

    :hover {
      color: rgba(255, 255, 255, 0.8);
    }
  `
}
