// @flow
import React from 'react'
import styled, { css } from 'styled-components'
import Spinner from './Spinner'
import type { Node } from 'react'

const Background = styled.span`
  background-color: var(--button-background);
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity var(--duration);
  z-index: -1;
`

const Button = styled.button`
  --spinner-background: var(--primary);

  align-items: center;
  background-color: transparent;
  border: 0;
  color: var(--button-color);
  display: flex;
  flex: 1;
  justify-content: center;
  padding: var(--button-gap);
  position: relative;
  text-align: center;
  text-decoration: none;
  z-index: 1;

  ${({ notActive }) =>
    notActive
      ? css`
          --button-background: var(--gray-dark);

          opacity: 0.8;
          transition: opacity var(--duration);

          :hover {
            opacity: 1;
          }
        `
      : css`
          font-weight: bold;

          ${({ disabled }) =>
            !disabled &&
            css`
              :hover {
                color: var(--button-color-hover);

                ${Background} {
                  background-color: var(--button-background-hover);
                  opacity: var(--button-hover-opacity);
                }
              }
            `};
        `};
`

type Props = {
  children?: Node,
  link?: boolean,
  loading?: boolean,
  notActive?: boolean
}

export default ({ children, link = false, loading = false, notActive, ...props }: Props) => (
  <Button as={link ? 'a' : undefined} disabled={loading} loading={loading} notActive={notActive} {...props}>
    {children}

    <Spinner size={1} visible={loading} />

    <Background />
  </Button>
)
