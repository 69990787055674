// @flow
import { combineReducers } from 'redux'
import first from 'lodash/first'
import flatten from 'lodash/flatten'
import { NAVIGATED, SET_FOLDERS_WITH_EFB_FILES } from '@components/Root/actions'
import { getParentsFromChildId } from './selectors'

const getFolderIdFromUrl = url => {
  const path = url.pathname.slice(1).split('/')

  if (!path) return null

  const [category, id] = path

  if (category !== 'library') return null

  return id || null
}

const activeFolderId = (state = null, { type, url }) => {
  switch (type) {
    case NAVIGATED:
      return getFolderIdFromUrl(url)

    default:
      return state
  }
}

const foldersWithEfbFiles = (state = [], { folders, type }) => {
  switch (type) {
    case SET_FOLDERS_WITH_EFB_FILES:
      const hasEfbFile = folders.filter(({ files }) => files.find(({ docType }) => docType === 'EFB'))

      const foldersIds = [
        ...new Set(flatten(hasEfbFile.map(({ id }) => getParentsFromChildId(id, folders))).map(({ id }) => id))
      ]

      return foldersIds

    default:
      return state
  }
}

const hidden = (state = false, { type, url }) => {
  switch (type) {
    case NAVIGATED:
      const category = first(url.pathname.slice(1).split('/'))

      return category === '' || category === 'library' ? false : true

    default:
      return state
  }
}

const openedFoldersIds = (state = [], { folderId, folders, opened, type, url }) => {
  switch (type) {
    case NAVIGATED:
      const urlFolderId = getFolderIdFromUrl(url)

      if (!urlFolderId) return []

      return getParentsFromChildId(urlFolderId, folders).map(({ id }) => id)

    default:
      return state
  }
}

export default combineReducers({
  activeFolderId,
  foldersWithEfbFiles,
  hidden,
  openedFoldersIds
})
