// @flow
import React, { Component, Fragment } from 'react'
import Auth from '@components/Auth'
import Breadcrumbs from '@components/Breadcrumbs'
import Confirm from '@components/Confirm'
import Content from '@components/Content'
import FileDetail from '@components/FileDetail'
import GlobalFilter from '@components/GlobalFilter'
import Logo from '@components/Logo'
import Logout from '@components/Logout'
import Navigation from '@components/Navigation'
import Search from '@components/Search'
import TopNavigation from '@components/TopNavigation'
import { Root } from '../styled'
import type { Folder } from '@config/types'

import { Mutation, Query } from 'react-apollo'
import { Queries } from 'efa-tools/auth'

type Props = {
  data: { folders: Folder[] },
  fileDetail: boolean,
  location: { href: string },
  modal: boolean,
  navigated: (uri: string, folders: Folder[]) => void,
  setFoldersWithEfbFiles: (folders: Folder[]) => void
}

export default class extends Component<Props> {
  componentDidMount = () => {
    const {
      data,
      location: { href },
      navigated
    } = this.props

    navigated(href, data ? data.folders : [])
  }

  componentDidUpdate = (prevProps: Props) => {
    const {
      data,
      location: { href },
      navigated,
      setFoldersWithEfbFiles
    } = this.props

    // folders recieved
    if (prevProps.data && !prevProps.data.folders && data && data.folders) {
      navigated(href, data.folders)

      setFoldersWithEfbFiles(data.folders)
    }

    // url changed
    if (prevProps.location.href !== href) navigated(href, data ? data.folders : [])
  }

  render() {
    const { fileDetail, modal } = this.props

    return (
      // <Queries>
      //   {({ setUser, user }) => (
      //     <Query query={user}>
      //       {({ data }) => (
      //         <Mutation mutation={setUser} variables={{ company: 'TVS', user: 'mobiletest2', password: '26uELkt9AJg984qs' }}>
      //           {fetch => (
      <Fragment>
        <Auth />

        <Confirm />

        {/* <button onClick={fetch}>Fetch user</button> */}

        {/* {console.log(data)} */}

        <Root.Wrapper fileDetail={fileDetail} modal={modal}>
          <Logo />
          <TopNavigation />
          <GlobalFilter />
          <Navigation />

          <Search />
          <Logout />
          <Breadcrumbs />
          <Content />
        </Root.Wrapper>

        <FileDetail />
      </Fragment>
      //           )}
      //         </Mutation>
      //       )}
      //     </Query>
      //   )}
      // </Queries>
    )
  }
}
