// @flow
import type { ConfirmModal, Dispatch } from '@config/types'

export const HIDE_CONFIRM_MODAL = 'HIDE_CONFIRM_MODAL'
export const HIDE_MODAL = 'HIDE_MODAL'
export const SHOW_CONFIRM_MODAL = 'SHOW_CONFIRM_MODAL'
export const SHOW_MODAL = 'SHOW_MODAL'
export const TOGGLE_ONLY_EFB = 'TOGGLE_ONLY_EFB'

export const hideConfirmModal = () => ({ type: HIDE_CONFIRM_MODAL })

export const hideModal = () => ({ type: HIDE_MODAL })

export const showConfirmModal = ({ actionType, ids }: ConfirmModal) => (dispatch: Dispatch) => {
  dispatch(showModal())

  dispatch({
    actionType,
    ids,
    type: SHOW_CONFIRM_MODAL
  })
}

export const showModal = () => ({ type: SHOW_MODAL })

export const toggleOnlyEfb = () => ({ type: TOGGLE_ONLY_EFB })
