// @flow
import styled, { css } from 'styled-components'
import { Button } from '@common'

export const Content = {
  Buttons: styled.div`
    background-color: var(--gray-light);
    display: flex;
    flex-shrink: 0;
    height: 2.5rem;

    > * {
      flex: 0 0 auto;
    }
  `,
  Close: styled(Button)`
    --button-background: transparent;
    --button-background-hover: transparent;
    --button-color: var(--black);
    --button-color-hover: var(--red);
    --button-gap: 0 1rem;

    margin-left: auto;
  `,
  Confirmed: styled.span`
    --gap: 0.25rem;

    align-items: center;
    background-color: var(--green);
    color: var(--white);
    display: inline-flex;
    font-size: 0.75rem;
    justify-content: center;
    margin-left: var(--gap);
    padding-left: var(--gap);
    padding-right: var(--gap);
    pointer-events: none;
    user-select: none;
  `,
  Favourite: styled(Button)`
    --button-background: transparent;
    --button-background-hover: var(--yellow);
    --button-color: var(--yellow);
    --button-color-hover: var(--white);
    --button-gap: 0 1rem;
  `,
  FileName: styled.a`
    color: var(--primary);
    text-decoration: none;

    :hover {
      text-decoration: underline;
    }
  `,
  Inner: styled.div`
    flex: 1;
    overflow: auto;
    padding: var(--gap);
  `,
  Keyword: styled.button`
    --gap: 0.25rem;

    background-color: var(--primary);
    border: 0;
    color: var(--white);
    display: block;
    font-size: 0.75rem;
    height: 1.5rem;
    margin-bottom: calc(var(--gap) / 2);
    margin-right: var(--gap);
    margin-top: calc(var(--gap) / 2);
    padding: 0 var(--gap);

    :hover {
      opacity: 0.8;
    }
  `,
  Keywords: styled.div`
    display: flex;
    flex-wrap: wrap;
  `,
  Label: styled.div`
    font-weight: bold;

    ::after {
      content: ':\\00a0';
    }
  `,
  Light: styled.span`
    color: var(--gray-dark);
  `,
  Row: styled.div`
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: var(--gap);
  `,
  Spinner: styled.div``,
  Value: styled.div``,
  Wrapper: styled.div`
    --spinner-color: var(--primary);
    --spinner-duration: 0;

    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
  `
}

export const Route = {
  Wrapper: styled.div`
    backface-visibility: hidden;
    background-color: var(--white);
    bottom: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 30rem;
    z-index: 9999;

    ${({ fileDetail }) =>
      !fileDetail &&
      css`
        transform: translateX(100%);
      `};
  `
}
