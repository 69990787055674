// @flow
import React from 'react'
import List from '../containers/List'
import { Content } from '../styled'

const Required = () => 'required'
const Stream = () => 'stream'
const Favourite = () => 'favourite'
const Search = () => 'search'

export default () => (
  <Content.Router>
    <Content.Route path="/">
      <List default />
      <List path="library/:folderId/*" />

      <List path="required" />
      <List path="required/:fileId" />

      <Stream path="stream" />
      <Stream path="stream/:fileId" />

      <Search path="search" />
      <Search path="search/:value/:fileId" />

      <List path="favourite" />
      <List path="favourite/:fileId" />
    </Content.Route>
  </Content.Router>
)
