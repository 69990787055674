// @flow
import { createContext } from 'react';
import gql from 'graphql-tag';
import * as state from './state';
import { setUser } from './mutations';
import { user } from './queries';
export var queries = function queries() {
  return {
    setUser: gql(setUser),
    user: gql(user)
  };
};

var _createContext = createContext(queries()),
    Queries = _createContext.Consumer;

export { Queries };
export { state };