// @flow
import { connect } from 'react-redux'
import { getCategory } from '@common/selectors'
import { getActiveFolderId, getParentsFromChildId } from '@components/Navigation/selectors'
import Breadcrumbs from '../components/Breadcrumbs'
import type { Folder } from '@config/types'

const mapStateToProps = state => ({
  category: getCategory(state),
  folderId: getActiveFolderId(state),
  getParentsFromChildId: (folderId: string, folders: Folder[]) => getParentsFromChildId(folderId, folders)
})

export default connect(mapStateToProps)(Breadcrumbs)
