// @flow
import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
  :root {
    /* --aqua: #5ac8fa;
    --blue-light: #d9ebff;
    --blue-light-2: #d2e9ff;
    --gray-light-2: #f7f7f7;
    --gray-light-3: #f0f0f0;
    --gray-light-4: #d6d6d9;
    --red-light: #ffd2d2;

    --black: #000;
    --blue: #007aff;
    --gray: #ceced2;
    --orange: #ff9500;
    --pink: #ff2d55;
    --white: #fff; */

    /* primary colors */
    --black: #000;
    --blue: #007aff;
    --gray: #ceced2;
    --gray-dark: #8e8e93;
    --gray-light: #efeff4;
    --green: #4cd964;
    --orange: #ff9500;
    --red: #ff3830;
    --white: #fff;
    --yellow: #fc0;

    /* primary dimensions */
    --gap: 1rem;
    --gap-small: 0.6rem;

    /* transitions */
    --duration: 0.1s;
    --duration-long: 0.5s;
  }

  html {
    /* layout background colors */
    --breadcrumbs: rgba(0, 0, 0, 0.05);
    --content: #f7f7f7;
    --search: rgba(0, 0, 0, 0.03);
    --sidebar: #333;

    /* states */
    --disabled: var(--gray);
    --primary: var(--blue);

    /* button */
    --button-background: var(--blue);
    --button-background-hover: var(--blue);
    --button-color: var(--white);
    --button-color-hover: var(--white);
    --button-gap: var(--gap);
    --button-hover-opacity: 0.8;

    /* file types */
    --file-excel: #007800;
    /* --file-other: var(--gray-dark); */
    --file-pdf: #ff3b30;
    --file-word: #007aff;

    /* spinner */
    --spinner-background: var(--white);
    --spinner-color: var(--white);
    --spinner-duration: var(--duration-long);
    --spinner-opacity: 0.9;

    font-size: 16px;
  }

  body {
    background-color: var(--white);
    margin: 0;
    overflow: hidden;
  }

  body,
  button,
  input {
    color: var(--black);
    font-family: arial;
    font-size: 1rem;
  }

  button {
    ::-moz-focus-inner {
      border: 0;
    }
  }
`
