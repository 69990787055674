// @flow
import React from 'react'
import { Button } from '@common'

type Props = {
  logout: () => void,
  token: string
}

export default ({ logout, token }: Props) => (token ? <Button onClick={logout}>Logout</Button> : null)
