// @flow
import React, { Fragment } from 'react'
import File from '../containers/File'
import { Files } from '../styled'
import type { File as FileType } from '@config/types'

type Props = {
  fileDetail?: string | null,
  files: FileType[],
  onlyEfb: boolean
}

export default ({ fileDetail, files, onlyEfb }: Props) => {
  files = files ? files.filter(({ docType }) => (onlyEfb ? docType === 'EFB' : true)) : []

  return (
    <Fragment>
      {files.length ? (
        files.map(file => <File file={file} key={file.id} opened={fileDetail ? file.id === fileDetail : false} />)
      ) : (
        <Files.Missing>No files...</Files.Missing>
      )}
    </Fragment>
  )
}
