// @flow
import { connect } from 'react-redux'
import { toggleOnlyEfb } from '@common/actions'
import { getOnlyEfb } from '@common/selectors'
import GlobalFilter from '../components/GlobalFilter'

const mapStateToProps = state => ({
  onlyEfb: getOnlyEfb(state)
})

const mapDispatchToProps = (dispatch, { folders }) => ({
  toggleOnlyEfb: () => dispatch(toggleOnlyEfb())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GlobalFilter)
