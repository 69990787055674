// @flow
export var defaults = {
  modal: false
};
export var resolvers = {
  Mutation: {
    hideModal: function hideModal(_, __, _ref) {
      var cache = _ref.cache;
      var data = {
        modal: false
      };
      cache.writeData({
        data: data
      });
      return false;
    },
    showModal: function showModal(_, __, _ref2) {
      var cache = _ref2.cache;
      var data = {
        modal: true
      };
      cache.writeData({
        data: data
      });
      return true;
    }
  }
};
var typeDefs = "\n  type Mutation {\n    hideModal: Boolean\n    showModal: Boolean\n  }\n\n  type Query {\n    modal: Boolean\n  }\n";