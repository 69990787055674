// @flow
import styled, { css } from 'styled-components'

export const Root = {
  Wrapper: styled.div`
    display: grid;
    grid-template-areas: 'logo search logout' 'top-navigation breadcrumbs breadcrumbs' 'global-filter content content' 'navigation content content';
    grid-template-columns: 20rem 1fr auto;
    grid-template-rows: 4rem 4rem auto 1fr;
    height: 100vh;

    ::before {
      background-color: var(--black);
      bottom: 0;
      content: '';
      left: 0;
      opacity: 0;
      pointer-events: none;
      position: fixed;
      right: 0;
      top: 0;
      transition: opacity var(--duration-long) ease;
      z-index: 50;
    }

    ${({ fileDetail }) =>
      fileDetail &&
      css`
        ::before {
          opacity: 0.2;
        }
      `};

    ${({ modal }) =>
      modal &&
      css`
        filter: blur(8px);
        transform: scale(1.02);
      `};
  `
}
