// @flow
import React from 'react'
import { GlobalFilter } from '../styled'

type Props = {
  onlyEfb: boolean,
  toggleOnlyEfb: () => void
}

export default ({ onlyEfb, toggleOnlyEfb }: Props) => (
  <GlobalFilter.Wrapper>
    <GlobalFilter.Checkbox checked={onlyEfb} id="only-efb" onChange={toggleOnlyEfb} />

    <GlobalFilter.Label htmlFor="only-efb">show only EFB</GlobalFilter.Label>
  </GlobalFilter.Wrapper>
)
