// @flow
import React, { Fragment } from 'react'
import { render } from 'react-dom'
import { ApolloProvider } from 'react-apollo'
import { Provider } from 'react-redux'
import { Router } from '@reach/router'
import { PersistGate } from 'redux-persist/integration/react'
import GlobalStyles from '@config/styles'
import apollo from '@config/apollo'
import store, { persistor } from '@config/redux'
import Root from '@components/Root'

const root = document.getElementById('root')

if (root)
  render(
    <Fragment>
      <GlobalStyles />

      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ApolloProvider client={apollo}>
            <Router>
              <Root default />
            </Router>
          </ApolloProvider>
        </PersistGate>
      </Provider>
    </Fragment>,
    root
  )
